import React, {useEffect, useState, useRef} from "react";
import {FormGroup, Label, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../helpers/intlMessages";
import {AdaButton, AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import {paymentMethod} from "../../commun/enum/paymentMethod";
import {useForm} from "react-hook-form";
import {BILLING_NAME, getHostByAppName} from "../../../service/host";
import * as service from "../../../service/crud.service";
import {GetOrganizationIdFromSession, GetUserFromSession} from "../../../service/session.service";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";

const context = "invoices";
export default function InvoiceMultiPayments({
                                           setPaymentModalShow,
                                           invoiceList,
                                           setModalSuccessShow,
                                       }) {
    const [paymentTypeList, setPaymentTypeList] = useState(paymentMethod);
    const [assetList, setAssetList] = useState([]);
    const [currentDate, setCurrentDate] = useState("");
    const [showAssetList, setShowAssetList] = useState(false);
    const [disabledAmount, setDisabledAmount] = useState(false);
    const [errorMessagePayment, setErrorMessagePayment] = useState("");

    const [totalNetToPay, setTotalNetToPay] = useState(0);

    const [loadingModal, setLoadingModal] = React.useState(false);

    const {
        register,
        handleSubmit,
        errors,
        setValue,
        reset,
        unregister
    } = useForm();

    useEffect(() => {
        register(
            {name: "designation"},
            {required: "Ce champ est obligatoire."}
        );
        register(
            {name: "paymentType"},
            {required: "Ce champ est obligatoire."}
        );
        register({name: "netAmount"}, {required: "Ce champ est obligatoire."});
        register(
            {name: "paymentDate"},
            {required: "Ce champ est obligatoire."}
        );
        register({name: "codeAsset"});
        register({name: "additionalInformation"});
        let date = new Date();
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear();
        if (dd < 10) {
            dd = "0" + dd;
        }
        if (mm < 10) {
            mm = "0" + mm;
        }
        let today = yyyy + "-" + mm + "-" + dd;
        console.log("currentDate", today);
        setCurrentDate(today);
    }, []);


    useEffect(() => {

        setTotalNetToPay(invoiceList.map(item => item.netToPay).reduce((prev, next) => prev + next))

    }, [invoiceList]);
    

   

    const handleChangePaymentType = selectedOption => {
        console.log("paymentType", selectedOption);
        setDisabledAmount(false);
        if (selectedOption !== null) {
            setValue("paymentType", selectedOption.value);
            
        } else {
            setValue("paymentType", undefined);
        }
    };

   
    
    
    const onSubmitPayment = async(data) => {
        console.log("in submit payment", data);
        if (data.netAmount != totalNetToPay) {
            setErrorMessagePayment(
                "Le montant TTC doit être égale à la somme des montants TTC des factures selectionnées : " + totalNetToPay + " €"
            );
            console.log("paiement bloqué");
            return;
        }
        setLoadingModal(true);

        invoiceList.forEach((invoice)=> {
            const fetchData = async () => {
                let userConnected = GetUserFromSession();

                 await service
                    .putSpec(`${getHostByAppName(BILLING_NAME)}/${context}/add_payment/${invoice.id}/`, {
                        ...data,
                        netAmount: Math.round(invoice.netToPay),
                        user: userConnected.firstName+" "+userConnected.lastName,
                        organization:GetOrganizationIdFromSession().id
                    })
                    .then(res => {
                        console.log(res.data);
                        //setInvoice(res.data);
                    })
                    .catch(function (error) {
                        setErrorMessagePayment(error.message);
                        console.log("error to print", error.message);

                    });
    
    


               
            };
           

            fetchData();
            console.log("end payment...")
            setLoadingModal(false);



        }
        
        )
        
    };

    

  


    return (
        <div>
            <form onSubmit={handleSubmit(onSubmitPayment)}>
                <Row>
                    <Col>
                        <div className="display-5 c-text-danger">
                            {errorMessagePayment}{" "}
                        </div>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col>
                        <FormGroup>
                            <IntlMessages id="client.designation"/>
                            <AdaInputText
                                name="designation"
                                type="text"
                                errors={errors}
                                innerRef={register}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <IntlMessages id="invoice.payment.type"/>
                            <AdaSelect
                                isClearable
                                name="paymentType"
                                errors={errors}
                                options={paymentTypeList}
                                onChange={handleChangePaymentType}
                                innerRef={register}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                
                <Row>
                    <Col>
                        <FormGroup>
                            <IntlMessages id="invoice.ttc.amount"/>
                            <AdaInputText
                                name="netAmount"
                                type="number"
                                placeholder="0.00"
                                step="0.01"
                                defaultValue={totalNetToPay}
                                errors={errors}
                                innerRef={register}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <IntlMessages id="invoice.date.paiement"/>
                            <AdaInputText
                                type="date"
                                name="paymentDate"
                                defaultValue={currentDate}
                                errors={errors}
                                innerRef={register}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <IntlMessages id="invoice.note"/>
                            <AdaInputText
                                name="additionalInformation"
                                type="textarea"
                                errors={errors}
                                innerRef={register}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="col-sm-12">
                    <Col className="col-sm-8"/>
                    <Col>
                        <FormGroup>
                            <AdaButton className="c-btn c-danger w-100" onClick={() => setPaymentModalShow(false)}>
                                <IntlMessages id="action.common.cancel"/>
                            </AdaButton>

                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <AdaButton className="c-btn c-warning w-100" type="submit" style={{marginLeft: 10}}>
                                <IntlMessages id="action.common.submit"/>
                            </AdaButton>
                        </FormGroup>
                    </Col>
                </Row>
            </form>
            <Modal
                show={loadingModal}
                size="sm"
                centered
                backdrop={"static"}
            >
                <div className="align-self-center ">
                    <Row className="pt-3">
                        <Col>
                            <Spinner animation="border" color="warning" role="status"
                                     style={{width: '5rem', height: '5rem'}}/>
                        </Col>
                    </Row>
                    <Row className="pt-3 pb-2"><Col>
                        <div className="display-5 c-text-dark" style={{fontWeight: "bold"}}>
                            Demande en cours ...
                        </div>
                    </Col></Row>
                </div>
            </Modal>
        </div>
    );
}
