import React, {useEffect, useState} from "react";
import {FormGroup, Row, Table} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../helpers/intlMessages";
import {BILLING_NAME, getHostByAppName} from "../../../service/host";
import * as service from "../../../service/crud.service";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";

const context = "payments";

export default function InvoicePaymentsTest({payments}) {

    const [modalShow, setModalShow] = React.useState(false);
    const [paymentId, setPaymentId] = React.useState();

    const [paymentList, setPaymentList] = React.useState();

    useEffect(() => {
       setPaymentList(payments);
    }, [payments]);

    const handlerOpenModalDelete = obj => {
        console.log("in delete element", obj);
        setPaymentId(obj.id)
        setModalShow(true);
    };

    const handlerDeleteAction = () => {
        console.log("in Delete action", paymentId);
        service.deletes(getHostByAppName(BILLING_NAME), context, callBackResDelete, ((error)=>{console.log(error)}), [paymentId])
    };

    const callBackResDelete = () => {
        let list = paymentList.filter(payment => payment.id !== paymentId)
       setPaymentList(list);
        setModalShow(false);
    }
 
    return (
        <div>
            <Row>
                <Col>
                    <Row className="pt-3 pb-3">
                        <Col>
                            <div>
                                <span className="hash"># </span>
                                <IntlMessages id="common.payment"/>
                            </div>
                        </Col>
                    </Row>
                    
                    

                    <Table hover responsive bordered striped>
                        <thead align="left">

                        <tr>
                            <th width="10%">
                                <div className="display-5 c-text-secondary"><IntlMessages id="common.date"/></div>
                            </th>
                            <th width="60%">
                                <div className="display-5 c-text-secondary"><IntlMessages id="common.wording"/></div>
                            </th>

                            <th width="10%">
                                <div className="display-5 c-text-secondary"><IntlMessages id="invoice.payment.type"/>
                                </div>
                            </th>

                            <th width="10%">
                                <div className="display-5 c-text-secondary"><IntlMessages id="invoice.net.amount"/>
                                </div>
                            </th>

                            <th width="10%">
                                <div className="display-5 c-text-secondary"><IntlMessages id="common.user"/></div>
                            </th>

                        </tr>
                        </thead>

                        <tbody>
                        {paymentList !== undefined && paymentList !== null && paymentList.length > 0 &&(
                            paymentList.map((payment, key) => (
                            <tr key={key}>

                                <td>{payment.paymentDate}</td>
                                <td>{payment.designation}</td>
                                <td>{payment.paymentType}</td>
                                <td>{payment.netAmount}</td>
                                <td>{payment.createdBy}</td>
                                <td>
                                    <div className="c-btn c-danger">
                                        <i
                                            className="fas fa-trash"
                                            style={{ fontSize: 7}}
                                            onClick={() => handlerOpenModalDelete(payment)}
                                        />
                                    </div>
                                </td>

                            </tr>
                        )))}
                        </tbody>
                    </Table>
                    <SweetAlert
                                warning
                                showCancel
                                show={modalShow}
                                confirmBtnText="Oui"
                                confirmBtnBsStyle="danger"
                                cancelBtnBsStyle="default"
                                title="Confirmation"
                                onConfirm={() => handlerDeleteAction()}
                                onCancel={() => setModalShow(false)}
                            >
                                <IntlMessages id="action.common.confirmation"/>
                            </SweetAlert>

                </Col>
            </Row>


        </div>
    );
}
