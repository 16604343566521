import React, {useEffect, useState} from "react";
import {FormGroup, Label, Row} from "reactstrap";
import Col from "reactstrap/es/Col";
import IntlMessages from "../../../helpers/intlMessages";
import {AdaInputText} from "@adaming/ada-react-component";
import {BILLING_NAME, getHostByAppName} from "../../../service/host";
import * as service from "../../../service/crud.service";

const context = "invoices";
export default function InvoiceInformations({
                                        register,
                                        setValue,
                                        errors,
                                        invoice,
                                        edit,
                                        periodDate, clientTermsOfPayment
                                    }) {
    const [currentDate, setCurrentDate] = useState("");
    const [paymentDueDate, setPaymentDueDate] = useState("");
    const [termsOfPayment, setTermsOfPayment] = useState("");
    const [dateObject, setDateObject] = useState({});

    useEffect(() => {
        console.log("invoiceeeessss", invoice);
     }, [invoice]);
 

    useEffect(
        () => {
            if (invoice.id !== undefined) {
                console.log("in invoice date edit",invoice);
                setCurrentDate(invoice.billingDate);
                setPaymentDueDate(invoice.paymentDueDate);
                setTermsOfPayment(invoice.termsOfPayment);
                setDateObject({
                    billingDate: invoice.billingDate,
                    paymentDueDate: invoice.paymentDueDate,
                    termsOfPayment: invoice.termsOfPayment
                });
            } else {
                let date = new Date();
                let dd = date.getDate();
                let mm = date.getMonth() + 1;
                let yyyy = date.getFullYear();
                if (dd < 10) {
                    dd = "0" + dd;
                }
                if (mm < 10) {
                    mm = "0" + mm;
                }
                let today = yyyy + "-" + mm + "-" + dd;
                console.log("currentDate", today);
                setCurrentDate(today);
                setPaymentDueDate(today);
                setDateObject({
                    billingDate: today,
                    paymentDueDate: today,
                    termsOfPayment: "0"
                });
            }
        },
        [invoice]
    );

    

    

  

   

    return (
        <div className="ptb-5">
<Row className="pt-3 pb-3">
                        <Col>
                            <div>
                                <span className="hash"># </span>
                                <IntlMessages id="invoice.details"/>
                            </div>
                        </Col>
                    </Row>
            <Row>
                <Col className="col-sm-2">
                    <FormGroup>
                        <IntlMessages id="invoices.numero"/>
                        <AdaInputText
                            type="text"
                            name="invoiceCode"
                            defaultValue={invoice.code}
                            errors={errors}
                            innerRef={register}
                            disabled={!edit}
                        />
                    </FormGroup>
                </Col>
                <Col className="col-sm-2">
                    <FormGroup>
                        <IntlMessages id="invoice.client"/>
                        <AdaInputText
                            type="text"
                            name="invoiceCode"
                            defaultValue={invoice.client?.name}
                            errors={errors}
                            innerRef={register}
                            disabled={!edit}
                        />
                    </FormGroup>
                </Col>
                <Col className="col-sm-2">
                    <FormGroup>
                        <IntlMessages id="invoice.date"/>
                        <AdaInputText
                            type="date"
                            name="billingDate"
                            defaultValue={currentDate}
                            errors={errors}
                            innerRef={register}
                            disabled={!edit}
                        />
                    </FormGroup>
                </Col>
                <Col className="col-sm-2">
                    <FormGroup>
                        <IntlMessages id="invoice.due.date"/>
                        <AdaInputText
                            type="date"
                            name="paymentDueDate"
                            defaultValue={paymentDueDate}
                            errors={errors}
                            innerRef={register}
                            disabled={!edit}
                        />
                    </FormGroup>
                </Col>

                <Col className="col-sm-2">
                    <FormGroup>
                        <IntlMessages id="invoice.net.amount"/>
                        <AdaInputText
                            name="amount"
                            type="number"
                            placeholder="0"
                            value={invoice.netAmount}
                            errors={errors}
                            innerRef={register}
                            disabled={!edit}
                        />
                    </FormGroup>
                </Col>
            </Row>
            
        </div>
    );
}
