import Invoices from "../views/billing/invoice/invoices"
import PaymentInvoices from "../views/billing/invoicepayment/invoices.payments"

import Reporting from "../views/billing/reporting/reporting"
import InvoicesForm from "../views/billing/invoice/create/new.invoice"
import InvoiceEditForm from "../views/billing/invoice/edit/edit.invoice"
import InvoiceDuplicateForm from "../views/billing/invoice/duplicate/duplicate.invoice.jsx"

import Assets from "../views/billing/asset/assets"
import AssetCreateFromInvoiceForm from "../views/billing/asset/create/new.asset.from.invoice"
import AssetForm from "../views/billing/asset/create/new.asset"
import AssetEditForm from "../views/billing/asset/edit/edit.asset"

import Payments from "../views/billing/payment/payments"
import PaymentForm from "../views/billing/payment/create/new.payment"
import ImportForm from "../views/billing/payment/import/import.payment"
import Maintenance from "../views/pages/error/maintenance";
import EditInvoicePayment from "../views/billing/payment/edit/edit.invoice.payment"
import InvoicePaymentEditForm from "../views/billing/invoicepayment/edit/edit.invoice.payment"

const billingRoutes = [
    {path: "/invoices", component: Invoices},
    {path: "/invoices/new", component: InvoicesForm},
    {path: "/invoices/:invoiceId/edit", component: InvoiceEditForm},
    {path: "/invoices/:invoiceId/duplicate", component: InvoiceDuplicateForm},

    {path: "/payments", component: PaymentInvoices},
    {path: "/payments/:invoiceId/edit", component: InvoicePaymentEditForm},

    
    {path: "/assets/new", component: AssetForm},
    {path: "/assets/:assetId/edit", component: AssetEditForm},
    {path: "/invoice/assets/:invoiceId/new", component: AssetCreateFromInvoiceForm},

    /*{path: "/payments", component: Payments},
    {path: "/payments/new", component: PaymentForm},
    {path: "/payments/upload", component: ImportForm},
    {path: "/payments/:paymentId/edit", component: EditInvoicePayment},*/

    {path: "/maintenance", component: Maintenance},

    {path: "/reporting", component: Reporting}
];

export default billingRoutes;