import React, {useEffect, useState} from "react";
import {AdaButton, AdaInputText, AdaSelect} from "@adaming/ada-react-component";
import  IntlMessages from "../../../helpers/intlMessages";
import {Col, FormGroup, Row} from "reactstrap";
import {Controller, useForm} from "react-hook-form";
import {month} from "../../commun/enum/month";
import * as service from "../../../service/crud.service";
import {BILLING_NAME, CRM_NAME, EMPLOYEE_NAME, getHostByAppName, ORGANIZATION_NAME} from "../../../service/host";
import * as specificService from "../../../service/employee.service";
import {GetOrganizationIdFromSession} from "../../../service/session.service";
import {connect} from "react-redux";

import filtersAction from "../../../redux/filters/actions";
const {updateFilters} = filtersAction;

const BillingPaymentAdvancedSearchForm = (props) => {
    const {register, handleSubmit, errors, setValue, reset, control} = useForm();
    const [organizationList, setOrganizationList] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [clientGroupList, setClientGroupList] = useState([]);
    const [yearList, setYearList] = useState([]);
    const [monthList, setMonthList] = useState(month);
    const [employeeList, setEmployeeList] = useState([]);
    const [entityList, setEntityList] = useState([]);
    const [contactList, setContactList] = useState([]);
    const [paymentTypeList, setPaymentTypeList] = useState([{key:0,value:"UNPAID",label:"Impayées"}, {key:1,value:"PAID",label:"Payées"}, {key:2,value:"UNPAID_OVERDUE",label:"Impayées et échues"}]);
    const [paymentType, setPaymentType] = useState();
    const handlerAdvancedSearch = props.handlerAdvancedSearch;
    const handlerCancelAdvancedSearch = props.handlerCancelAdvancedSearch;
    const handlerChangeType = props.handlerChangeType;




    useEffect(() => {
        /*List Organization Client rest service */
        //service.getAllForSelect(getHostByAppName(ORGANIZATION_NAME), `organizations`, setOrganizationList)
        service.getAllForSelect(getHostByAppName(CRM_NAME), `clients`, setClientList)
        service.getAllObj(getHostByAppName(CRM_NAME), `clients/groups`, setClientGroupList)
        service.getAllObj(getHostByAppName(BILLING_NAME), `billings/getYear`, setYearList)
        specificService.getAllPersonSelect(getHostByAppName(EMPLOYEE_NAME), `employees`, setEmployeeList)
        service.getAllObj(getHostByAppName(BILLING_NAME), `invoices/client_contacts`, setContactList)
        setValue("month", getSelectedMounth(monthList));
        setValue("paymentType", getSelectedPaymentType(paymentTypeList));

        reset({
            type : "INVOICE",
            transmitter: GetOrganizationIdFromSession().id,
        });
        props.updateFilters({...props.filters.invoiceFilters,
            type : "INVOICE",
            transmitter: GetOrganizationIdFromSession().id,
        })


    }, []);

    useEffect(() => {
        setValue("client", getSelectedClient(clientList));
    }, [clientList]);
    useEffect(() => {
        setValue("clientGroup", getSelectedClientGroup(clientGroupList));
    }, [clientGroupList]);
    useEffect(() => {
        setValue("entity", getSelectedEntity(entityList));
    }, [entityList]);
    useEffect(() => {
        setValue("employee", getSelectedEmployee(employeeList));
    }, [employeeList]);
    useEffect(() => {
        setValue("month", getSelectedMounth(monthList));
    }, [monthList]);
    useEffect(() => {
        setValue("year", getSelectedYear(yearList));
    }, [yearList]);
    useEffect(() => {
        setValue("paymentType", getSelectedPaymentType(paymentTypeList));
    }, [paymentTypeList]);
    useEffect(() => {
        setValue("billingContact", getSelectedBillingContact(contactList));
    }, [contactList]);


    const onSubmitInvoiceSearch = (data) => {
        let filters = {...data, ...props.filters.invoiceFilters};
        handlerAdvancedSearch(filters);
    };

    const clear =() =>{
        reset({
            client: null,
            clientGroup: null,
            entity: null,
            month: null,
            year: null,
            paymentDueDate: null,
            billingPeriodStart: null,
            billingPeriodEnd: null,
            transmitter: {organization : GetOrganizationIdFromSession().id},
            employee: null,
            reference: null,
            billingContact : null,
            paymentType : null,
            type : "INVOICE"
        });
        props.updateFilters({...props.filters.invoiceFilters,
            client: null,
            clientGroup : null,
            entity: null,
            month: null,
            year: null,
            paymentDueDate: null,
            billingPeriodStart: null,
            billingPeriodEnd: null,
            transmitter: GetOrganizationIdFromSession().id,
            employee: null,
            reference: null,
            billingContact : null,
            paymentType : null,
            type : "INVOICE"
        })
        handlerCancelAdvancedSearch();
    }

    const handleChangeYear = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        if (selectedOption !== null) {
            props.updateFilters({...props.filters.invoiceFilters, year: selectedOption.value})
        } else {
            props.updateFilters({...props.filters.invoiceFilters, year: null})
        }
    };

    const handleChangeMonth = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        if (selectedOption !== null) {
            props.updateFilters({...props.filters.invoiceFilters, month: selectedOption.value})
        } else {
            props.updateFilters({...props.filters.invoiceFilters, month: null})
        }
    };

    const handleChangeEmployee = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        if (selectedOption !== null) {
            setValue("employee", selectedOption.key);
            props.updateFilters({...props.filters.invoiceFilters, employee: selectedOption.key})
        } else {
            setValue("employee", null);
            props.updateFilters({...props.filters.invoiceFilters, employee: null})
        }
    };

    const handleChangeClient = selectedOptionTab => {
        console.log("clientList", clientList);
        let selectedOption = selectedOptionTab[0];
        if (selectedOption !== null) {
            setValue("client", selectedOption.key);
            setValue("entity", null);
            props.updateFilters({...props.filters.invoiceFilters, client: selectedOption.key, entity: null})
            service.getAllForSelect(getHostByAppName(CRM_NAME), `entitys/findBy/client/${selectedOption.key}`, setEntityList)
        } else {
            setValue("client", null);
            setValue("entity", null);
            setEntityList([])
            props.updateFilters({...props.filters.invoiceFilters, client: null, entity: null})
        }
    };

    const handleChangeClientGroup = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        if (selectedOption !== null) {
            setValue("clientGroup", selectedOption.key);
            setValue("client", null);
            console.log("clientGroup changed ",selectedOption.key )
            service.getAllForSelect(getHostByAppName(CRM_NAME), `clients/findByGroup/${selectedOption.key}`, setClientList)

            props.updateFilters({...props.filters.invoiceFilters, clientGroup: selectedOption.value})
        } else {
            setValue("clientGroup", null);
            service.getAllForSelect(getHostByAppName(CRM_NAME), `clients`, setClientList)
            props.updateFilters({...props.filters.invoiceFilters, clientGroup: null})
        }
    };

    const handleChangeEntity = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        if (selectedOption !== null) {
            setValue("entity", selectedOption.key);
            props.updateFilters({...props.filters.invoiceFilters, entity: selectedOption.key})
        } else {
            setValue("entity", null);
            props.updateFilters({...props.filters.invoiceFilters, entity: null})
        }
    };

    const handleChangePaymentType = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        if (selectedOption !== null) {
            setValue("paymentType", selectedOption.value);
            setPaymentType(selectedOption.value);
            props.updateFilters({...props.filters.invoiceFilters, paymentType: selectedOption.value})
        } else {
            setValue("paymentType", null);
            setPaymentType(null);
            props.updateFilters({...props.filters.invoiceFilters, paymentType: null})
        }

    };


    const handleChangeContact = selectedOptionTab => {
        let selectedOption = selectedOptionTab[0];
        if (selectedOption !== null) {
            setValue("billingContact", selectedOption.value);
            props.updateFilters({...props.filters.invoiceFilters, billingContact: selectedOption.value})
        } else {
            setValue("billingContact", null);
            props.updateFilters({...props.filters.invoiceFilters, billingContact: null})
        }
    };

   

    const getSelectedClient = (clientList) =>{
      const filtered =  clientList.find(client => client.object.id === props.filters.invoiceFilters.client);
      if(filtered){
        return filtered
      }else return null;
    }

    const getSelectedEntity = (entityList) =>{
      const filtered =  entityList.find(entity => entity.object.id === props.filters.invoiceFilters.entity);
      if(filtered){
        return filtered
      }else return null;
    }

    const getSelectedEmployee = (employeeList) =>{
        const filtered =  employeeList.find(employee => employee.object.id === props.filters.invoiceFilters.employee);
        if(filtered){
          return filtered
        }else return null;
      }
      const getSelectedMounth = (monthList) =>{
        const filtered =  monthList.find(m => m.value === props.filters.invoiceFilters.month);
        if(filtered){
          return filtered
        }else return null;
      }

      const getSelectedYear = (yearList) =>{
        const filtered =  yearList.find(y => y.value === props.filters.invoiceFilters.year);
        if(filtered){
          return filtered
        }else return null;
      }

      const getSelectedClientGroup = (clientGroupList) =>{
        const filtered =  clientGroupList.find(y => y.value === props.filters.invoiceFilters.clientGroup);
        if(filtered){
          return filtered
        }else return null;
      }

      const getSelectedBillingContact = (contactList) =>{
        const filtered =  contactList.find(y => y.value === props.filters.invoiceFilters.billingContact);
        if(filtered){
          return filtered
        }else return null;
      }

      const getSelectedPaymentType = (paymentTypeList)=>{
        const filtered =  paymentTypeList.find(y => y.value === props.filters.invoiceFilters.paymentType);
        if(filtered){
            return filtered
        }else return null;
      }



    return (
        <div>
            <form onSubmit={handleSubmit(onSubmitInvoiceSearch)}>
                <Row className="col-sm-12 pt-3">
                    <Col className="col-sm-3">
                        <Row>
                            <Col>
                                <FormGroup>
                                    {" "}
                                    <div className="grey--text">
                                        <IntlMessages id="invoice.client"/>
                                    </div>
                                    <Controller
                                        as={<AdaSelect name={"client"}/>}
                                        name={"client"}
                                        isClearable
                                        errors={errors}
                                        options={clientList}
                                        onChange={handleChangeClient}
                                        control={control}
                                        innerRef={register}
                                                />
                                </FormGroup>
                            </Col>
                            </Row>
                            <Row>
                            <Col>
                                <FormGroup>
                                    {" "}
                                    <div className="grey--text">
                                        <IntlMessages id="invoice.group.client"/>
                                    </div>
                                    <Controller
                                        as={<AdaSelect name={"clientGroup"}/>}
                                        name={"clientGroup"}
                                        isClearable
                                        errors={errors}
                                        options={clientGroupList}
                                        onChange={handleChangeClientGroup}
                                        control={control}
                                        innerRef={register}
                                                />
                                </FormGroup>
                            </Col>
                            </Row>
                           
                        <Row>
                            <Col>
                                <FormGroup>
                                    {" "}
                                    <div className="grey--text">
                                        <IntlMessages id="invoice.entity"/>
                                    </div>
                                    <Controller
                                        as={<AdaSelect name={"entity"}/>}
                                        name={"entity"}
                                        isClearable
                                        errors={errors}
                                        options={entityList}
                                        onChange={handleChangeEntity}
                                        control={control}
                                        innerRef={register}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                        <Col>
                            <FormGroup>
                                {" "}
                                <div className="grey--text">
                                    <IntlMessages id="invoice.contact"/>
                                </div>

                                <Controller
                                        as={<AdaSelect name={"client"}/>}
                                        name={"billingContact"}
                                        isClearable
                                        errors={errors}
                                        options={contactList}
                                        onChange={handleChangeContact}
                                        control={control}
                                        innerRef={register}
                                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    </Col>


                    <Col className="col-sm-4 pl-5">
                        <Row>
                            <Col>
                                <FormGroup>
                                    <div className="grey--text">
                                        <IntlMessages id="invoice.period.search.from"/>
                                    </div>
                                    <Row>
                                        <Col>
                                            <Controller
                                                as={<AdaSelect name={"month"}/>}
                                                name={"month"}
                                                isClearable
                                                errors={errors}
                                                options={monthList}
                                                onChange={handleChangeMonth}
                                                control={control}
                                                innerRef={register}
                                            />
                                        </Col>
                                        <Col className="pr-5">
                                            <Controller
                                                as={<AdaSelect name={"year"}/>}
                                                name={"year"}
                                                isClearable
                                                errors={errors}
                                                options={yearList}
                                                onChange={handleChangeYear}
                                                control={control}
                                                innerRef={register}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-sm-11">
                                <Row>
                                    <Col className="col-sm-6">
                                        <FormGroup>
                                            <div className="grey--text">
                                                <IntlMessages id="invoice.from.period"/>
                                            </div>
                                            <AdaInputText
                                                type="date" style={{height:38}}
                                                name="billingPeriodStart"
                                                errors={errors}
                                                innerRef={register}
                                                onBlur={e => props.updateFilters({...props.filters.invoiceFilters, billingPeriodStart: e.target.value})}
                                                defaultValue={props.filters.invoiceFilters.billingPeriodStart}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col className="col-sm-6">
                                        <FormGroup>
                                            <div className="grey--text">
                                                <IntlMessages id="invoice.end.period"/>
                                            </div>
                                            <AdaInputText
                                                type="date" style={{height:38}}
                                                name="billingPeriodEnd"
                                                errors={errors}
                                                innerRef={register}
                                                onBlur={e => props.updateFilters({...props.filters.invoiceFilters, billingPeriodEnd: e.target.value})}
                                                defaultValue={props.filters.invoiceFilters.billingPeriodEnd}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <div className="grey--text">
                                        <IntlMessages id="billing.type"/>
                                    </div>
                                    <Controller
                                        as={<AdaSelect name={"paymentType"}/>}
                                        name={"paymentType"}
                                        errors={errors}
                                        options={paymentTypeList}
                                        onChange={handleChangePaymentType}
                                        control={control}
                                        innerRef={register}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>

                    <Col className="col-sm-2 pl-5">
                        <Row>
                            <Col>
                                <FormGroup>
                                    <div className="grey--text">
                                        <IntlMessages id="invoice.reference"/>
                                    </div>
                                    <AdaInputText
                                        type="text" style={{height:38}}
                                        name="reference"
                                        errors={errors}
                                        onBlur={e => props.updateFilters({...props.filters.invoiceFilters, reference: e.target.value})}
                                        innerRef={register}
                                        defaultValue={props.filters.invoiceFilters.reference}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col >
                                <FormGroup>
                                    <div className="grey--text">
                                        <IntlMessages id="common.collaborator"/>
                                    </div>
                                    <Controller
                                        as={<AdaSelect name={"employee"}/>}
                                        name={"employee"}
                                        isClearable
                                        errors={errors}
                                        options={employeeList}
                                        onChange={handleChangeEmployee}
                                        control={control}
                                        innerRef={register}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="col-sm-3  pl-5">
                        <Row>
                            <Col className="col-sm-11">
                                {paymentType !== "UNPAID_OVERDUE" &&

                                <FormGroup>
                                    <div className="grey--text">
                                        <IntlMessages id="invoice.due.date"/>
                                    </div>
                                    <AdaInputText
                                        type="date" style={{height:38}}
                                        name="paymentDueDate"
                                        errors={errors}
                                        innerRef={register}
                                        onBlur={e => props.updateFilters({...props.filters.invoiceFilters, paymentDueDate: e.target.value})}
                                        defaultValue={props.filters.invoiceFilters.paymentDueDate}
                                    />
                                </FormGroup>
                                }

                            </Col>
                        </Row>
                        <Row>
                            <Col className="pt-4 pl-5">
                                <FormGroup>
                                    <AdaButton style={{height: 35}} className="c-danger col-xl-5" onClick={()=>clear()}>
                                        <IntlMessages id="action.common.cancel"/>
                                    </AdaButton>
                                    <AdaButton
                                        className="c-warning col-xl-5"
                                        type="submit"
                                        style={{marginLeft: 10, height: 35}}
                                    >
                                        <IntlMessages id="common.search"/>
                                    </AdaButton>
                                </FormGroup>
                            </Col>
                        </Row>

                    </Col>

                </Row>
            </form>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        ...state.filters,
        filters: state.filters
    };
};

export default connect(
    mapStateToProps,
    {
        updateFilters
    }
)(BillingPaymentAdvancedSearchForm);
