import React from "react";

export const  paymentMethod = [
    {
        key: "Virement",
        label: "Virement",
        value: "Virement"
    },
    {
        key: "Espèce",
        label: "Espèce",
        value: "Espèce"
    },
    {
        key: "Chèque",
        label: "Chèque",
        value: "Chèque"
    },
    {
        key: "Avoir",
        label: "Avoir",
        value: "Avoir"
    }
]
export const  paymentMethodForEdit = [
    {
        key: "Virement",
        label: "Virement",
        value: "Virement"
    },
    {
        key: "Espèce",
        label: "Espèce",
        value: "Espèce"
    },
    {
        key: "Chèque",
        label: "Chèque",
        value: "Chèque"
    }
   
]