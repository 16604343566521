import React, {useState} from "react";
import {columnsPayments} from "./data/columns";
import {AdaActions, AdaFilterSubHeader, AdaTable} from "@adaming/ada-react-component";
import IntlMessages from "../../../helpers/intlMessages";
import {withRouter} from "react-router";
import SweetAlert from "react-bootstrap-sweetalert";
import * as service from "../../../service/crud.service";
import {BILLING_NAME, getHostByAppName} from "../../../service/host";
import BillingAdvancedSearchForm from "../research/advanced.research";
import BillingAdvancedSearchPaymentForm from "../research/advanced.research.payment";
import {GetOrganizationIdFromSession} from "../../../service/session.service";
import filtersAction from "../../../redux/filters/actions";
import {connect} from "react-redux";


const {updateFiltersPayment} = filtersAction;
const context = "payments";
const Payments = props => {
    const [payments, setPayments] = useState([]);
    const [loadingModal, setLoadingModal] = React.useState(false);
    /*payments detail */
    const [hideDetailPanel, setHideDetailPanel] = useState(true);
    /*DataTable state*/
    const [selectedRows, setSelectedRows] = useState([]);
    const [filtered, setFiltered] = useState([]);
    /*Delete action and state modal*/
    const [modalShow, setModalShow] = React.useState(false);
    const [modalSuccessShow, setModalSuccessShow] = useState(false);
    /*Action List*/
    const [actionsList, setActionsList] = useState([]);
    const [advancedSearchShow, setAdvancedSearchShow] = React.useState(false);

    const paginationRowsPerPageOptions = [5,10,20,100]
    const defaultSizePerPage = paginationRowsPerPageOptions[1];
    const defaultPage = 0;
    const [currentPaginationPerRow, setCurrentPaginationPerRow] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [maxContent, setMaxContent] = useState(50);

    const initActionsList = () => {
        return [

            {
                actionName: <IntlMessages id={`payment.import`}/>,
                href: `/${context}/` + "" + `upload`,
                actionHandler: null,
                disabled: false
            },{
                actionName: <IntlMessages id={`action.common.edit`}/>,
                href: `/${context}/` + "" + `/edit`,
                actionHandler: null,
                disabled: true
            }
        ];
    };

    const callbackRes = res =>{
        setPayments(res.data);
        setFiltered(res.data);
        setMaxContent(res.headers['x-content-max'])
    }


    const initList = () =>{
        handlerAdvancedSearch(props.filters.paymentFilters)
    }


    /*Create operation List*/
    React.useEffect(
        () => {
            let actionsValue = initActionsList();
            setActionsList(actionsValue);
            setHideDetailPanel(true);
            if (selectedRows !== undefined && selectedRows.length > 0) {
                /*Activate : Delete Action*/
                //actionsValue[0] = {...actionsValue[0], disabled: false};
                /*Activate : Activate Action*/
                //actionsValue[2] = {...actionsValue[2], disabled: false};
                /*Activate : Disable Action*/
                //actionsValue[3] = {...actionsValue[3], disabled: false};
                if (selectedRows.length === 1) {
                    /*Activate : Edit Action*/
                    actionsValue[1] = {
                        ...actionsValue[1],
                        href: `/${context}/${selectedRows[0].id}/edit`,
                        disabled: false
                    };
                    /*show detail true*/
                    setHideDetailPanel(false);
                }
                setActionsList(actionsValue);
            }
        },
        [selectedRows.length]
    );

    React.useEffect(()=>{     
        handlerAdvancedSearch({transmitter: GetOrganizationIdFromSession().id},currentPaginationPerRow, currentPage)
    },[currentPaginationPerRow,currentPage])


    const handleRowSelected = React.useCallback(state => {
        setSelectedRows(state.selectedRows);
    }, []);

    const actionsComponent = (
        <AdaActions context={context} actionsObj={actionsList} selectedRows={selectedRows}
                    showActions={{exportExcel:false, add:false}} host={`${getHostByAppName(BILLING_NAME)}`}/>
    );

    const adaFilterHeader = (
        <AdaFilterSubHeader
            columns={columnsPayments}
            items={payments}
            setFiltredItems={setFiltered}
        />
    );

    const handlerAdvancedSearch = React.useCallback((data,size,page) => {
        if(size == null){
            size = defaultSizePerPage;
        }

        if(page == null){
            page = defaultPage;
        }
        service.search(
            getHostByAppName(BILLING_NAME),
            `${context}/advanced_search/get?size=${size}&page=${page}&sort=tecCodeInvoice,DESC`,
            callbackRes,
            (error) => {
            },
            data
        );
    }, []);

    const handlerCancelAdvancedSearch = React.useCallback(() => {
        initList();
    }, []);

    return (
        <div>
            <div className="row ma-0">
                <div className="col-sm-12 ptb-15">
                    <div className="roe-card-style">
                        <div className="roe-card-body">
                            <fieldset>
                                <legend>
                                    <div className="display-3 grey--text">
                                        <IntlMessages id="common.advanced.research"/>

                                        {!advancedSearchShow && (
                                            <i
                                                className="fas fa-search-plus"
                                                style={{fontSize: 25, paddingLeft: 15}}
                                                onClick={() =>
                                                    setAdvancedSearchShow(!advancedSearchShow)
                                                }
                                            />
                                        )}
                                        {advancedSearchShow && (
                                            <i
                                                className="fas fa-search-minus"
                                                style={{fontSize: 25, paddingLeft: 15}}
                                                onClick={() =>
                                                    setAdvancedSearchShow(!advancedSearchShow)
                                                }
                                            />
                                        )}
                                    </div>
                                </legend>
                                {advancedSearchShow && (
                                    <BillingAdvancedSearchPaymentForm handlerCancelAdvancedSearch={handlerCancelAdvancedSearch}
                                                               handlerAdvancedSearch={handlerAdvancedSearch}
                                                               isAsset={false}
                                                               context={context}
                                    />
                                )}
                            </fieldset>
                        </div>
                    </div>
                    <div className="roe-card-style">
                        <div className="roe-card-body">
                            <AdaTable
                                columns={columnsPayments}
                                data={filtered}
                                selectableRows
                                actions={actionsComponent}
                                onRowSelected={handleRowSelected}
                                pagination
                                paginationServer={true}
                                paginationTotalRows={maxContent}
                                paginationPerPage={currentPaginationPerRow}
                                paginationRowsPerPageOptions={paginationRowsPerPageOptions}
                                onChangeRowsPerPage={(rowPerPage) =>{
                                    setCurrentPaginationPerRow(rowPerPage)
                                }}
                                onChangePage={page => {
                                    setCurrentPage(page - 1);
                                }}
                            />
                            <br/>
                            {/*<Details
                                hideDetails={hideDetailPanel}
                                selectedElement={
                                    selectedRows.length === 1 ? selectedRows[0] : null
                                }
                                context={context}
                            />*/}

                            <SweetAlert
                                success
                                show={modalSuccessShow}
                                title="Success!"
                                onConfirm={() => setModalSuccessShow(false)}
                                confirmBtnCssClass="sweet-alert-confirm-button"
                                cancelBtnCssClass="sweet-alert-cancle-button"
                            >
                                <IntlMessages id="action.common.succes"/>
                            </SweetAlert>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        ...state.filters,
        filters: state.filters
    };
};

export default connect(
    mapStateToProps,
    {
        updateFiltersPayment
    }
)(Payments);
