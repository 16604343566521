import React from "react";

import {AdaButton} from "@adaming/ada-react-component";
import * as service from "../../../../service/crud.service";
import {BILLING_NAME, DOCUMENT_NAME, getHostByAppName} from "../../../../service/host";
import { GetOrganizationIdFromSession } from "../../../../service/session.service";
import dateFormat from "../../../commun/date.format";



export default function VisualizeReportingDocumentAction({

                                                    reporting,
                                                    setErrorMessage,
                                                    context, setModalLoadingShow, 
                                                    
                                                }) {

    function dataURLtoFile(dataUrl, filename, type) {

        let   bstr = atob(dataUrl),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {type: type});
    }

    const openDocument = (document) => {
        let file = dataURLtoFile(document.contents, document.documentName, document.documentType);
        console.log("doc", file)
        let fileURL = URL.createObjectURL(file)
        console.log("fileURL", fileURL)
        window.open(fileURL);
    }

    const callbackRes = res => {
        console.log("doc",res.data)
        openDocument(res.data);
        setModalLoadingShow(false)
    };

    const callbackError = error => {
        setErrorMessage(error.message);
        setModalLoadingShow(false)
        console.log("error to print", error.message);
    };

    const mapData = reporting =>{
        return { billingCriteria : reporting.filters,
             reportingData : {...reporting,
            transmitter : GetOrganizationIdFromSession(),
            reportingDate : dateFormat(new Date()),
        }}
    }
    const handlerClickVisualize = () => {
        setModalLoadingShow(true)
        console.log(JSON.stringify(mapData(reporting)));
        service.put(
            getHostByAppName(BILLING_NAME),
            `reporting/reporting/`,
            callbackRes,
            callbackError,
            mapData(reporting)
        );
    };

    return (
        <div>
            <AdaButton style={{height: 32, width: 120}} className="c-btn ma-5 no-border c-outline-primary" onClick={() => handlerClickVisualize()}>
               
               
                <i className="far fa-file-pdf" style={{fontSize: 12}}> {( "Télécharger")}</i>
            </AdaButton>

        </div>
    );
}
